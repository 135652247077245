import React from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
      <header className="sticky md:relative top-0 bg-white z-[2] shadow-[0_3px_6px_0_rgba(0,0,0,0.16)]">
        <div className="container my-0 mx-auto flex justify-between items-center py-[20px]  px-[16.5px] sm:px-0">
          <img
            src="/images/logo-playcon@3x.webp"
            alt="logo-playcon@3x.webp"
            className="hover:cursor-pointer"
            width={140}
            onClick={() => navigate("/")}
          />
          <RxHamburgerMenu
            size={30}
            className="hover:cursor-pointer md:hidden"
            onClick={() => {
              if (searchParams.get("hamburger")) {
                searchParams.delete("hamburger");
              } else {
                searchParams.append("hamburger", "true");
              }
              setSearchParams(searchParams);
            }}
          />
          <div
            className={`fixed inset-0 bg-black bg-opacity-30 z-[2] md:hidden ${
              searchParams.get("hamburger") === null ? "!hidden" : "mt-[75px]"
            }`}
            onClick={() => setSearchParams({}, { replace: true })}
          ></div>
          <ul
            className={`fixed md:relative right-0 top-0 bottom-0 flex gap-[25px] md:gap-[51px] bg-white z-[2] md:z-0 md:bg-transparent md:!translate-x-0 duration-0 ${
              searchParams.get("hamburger") === null
                ? "translate-x-[100%]"
                : "translate-x-0"
            } flex-col md:flex-row p-[25px] md:p-0 w-full mt-[72px] border-t-[1px] h-[265px] md:w-[unset] md:mt-[unset] md:h-[unset] md:border-t-0`}
          >
            <li
              className={`hover:text-[#2760e5] font-bold hover:cursor-pointer ${
                location.pathname === "/" ? "!text-[#2760e5]" : "#222"
              }`}
              onClick={() => {
                if (searchParams.get("hamburger")) {
                  navigate("/", { replace: true });
                } else {
                  navigate("/");
                }
              }}
            >
              HOME
            </li>
            <li
              className={`hover:text-[#2760e5] font-bold hover:cursor-pointer ${
                location.pathname === "/about" ? "!text-[#2760e5]" : "#222"
              }`}
              onClick={() => {
                if (searchParams.get("hamburger")) {
                  navigate("/about", { replace: true });
                } else {
                  navigate("/about");
                }
              }}
            >
              ABOUT US
            </li>
            <li
              className={`hover:text-[#2760e5] font-bold hover:cursor-pointer ${
                /(^\/ip$|^\/ip\/)/.test(location.pathname) === true
                  ? "!text-[#2760e5]"
                  : "#222"
              }`}
              onClick={() => {
                if (searchParams.get("hamburger")) {
                  navigate("/ip", { replace: true });
                } else {
                  navigate("/ip");
                }
              }}
            >
              IP LIST
            </li>
            <li
              className={`hover:text-[#2760e5] font-bold hover:cursor-pointer ${
                location.pathname === "/contact" ? "!text-[#2760e5]" : "#222"
              }`}
              onClick={() => {
                if (searchParams.get("hamburger")) {
                  navigate("/contact", { replace: true });
                } else {
                  navigate("/contact");
                }
              }}
            >
              CONTRACT
            </li>
            <li
              className={`hover:text-[#2760e5] font-bold hover:cursor-pointer ${
                location.pathname === "/inform" ? "!text-[#2760e5]" : "#222"
              }`}
              onClick={() => {
                if (searchParams.get("hamburger")) {
                  navigate("/inform?page=1", { replace: true });
                } else {
                  navigate("/inform?page=1");
                }
              }}
            >
              INFORM
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Header;
