import React, { useState, useEffect } from "react";

const Footer = () => {
  interface List {
    code: string;
    status: number;
    success: boolean;
    message: string;
    data: any;
  }

  const sel_lang = "KR";

  const [familySitelist, setFamilySitelist] = useState<List>();
  const movePage = (link: string) => {
    const $a = document.createElement("a");
    $a.href = link;
    $a.target = "_blank";
    $a.click();
  };
  const get_familysiteList = async () => {
    const data = await (
      await fetch(
        `https://playcon.net/api/user/main/familysite/list/${sel_lang}/all`,
        {
          method: "GET",
        }
      )
    ).json();

    setFamilySitelist(data);
  };

  useEffect(() => {
    get_familysiteList();
  }, []);

  const [showFamilySites, setShowFamilySites] = useState(false);
  return (
    <>
      <footer className="bg-[#222] text-white p-[50px]">
        <div className="container mx-auto flex justify-between flex-col sm:flex-row items-center sm:items-start">
          <div className="flex flex-col gap-[10px] items-center sm:items-start">
            <p>(주)플레이콘</p>
            <p>(63178) 제주특별자치도 제주시 서사로 83 3층</p>
            <table>
              <tbody>
                <tr>
                  <td width={100}>Tel</td>
                  <td>070-8825-5004</td>
                </tr>
                <tr>
                  <td className="text-nowrap">E-Mail</td>
                  <td>playcon@ideaconcert.com</td>
                </tr>
              </tbody>
            </table>
            <p className="mt-[20px] mb-[30px] sm:mb-0">
              ⓒ 2024 PLAYCON Co., Ltd. All Right Reserved.
            </p>
          </div>
          <div className="hover:cursor-pointer">
            <button
              className="px-[32px] py-[8px] text-[#222] bg-white"
              onClick={() =>
                setShowFamilySites((initialState) => !initialState)
              }
            >
              Family sites
            </button>
            <ul
              className={` bg-white flex-col tracking-[-0.24px] text-center border-t border-[#ddd] text-black ${
                showFamilySites === true ? "flex" : "hidden"
              }`}
            >
              {typeof familySitelist !== "undefined" ? (
                <>
                  {Object.keys(familySitelist.data).map((_, index) => (
                    <li
                      key={index}
                      className="hover:bg-[#ddd] py-[10px]"
                      onClick={() => {
                        movePage(familySitelist.data[index].link);
                      }}
                    >
                      {familySitelist.data[index].sitename}
                    </li>
                  ))}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
