import React, { useState, useEffect, useRef } from "react";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { useForm, Form, FieldValues, FormSubmitHandler } from "react-hook-form";
import Header from "../components/Header";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Footer from "../components/Footer";
import { GoCheckCircle } from "react-icons/go";

const sm = 640;
const md = 768;
const lg = 1024;

const Main = () => {
  interface List {
    code: string;
    status: number;
    success: boolean;
    message: string;
    data: any;
  }

  const sel_lang = "KR";

  const [historylist, setHistoryList] = useState<List>();
  const [langList, setLangList] = useState<List>();

  const [inquiryTypelist, setInquiryTypelist] = useState<List>();

  const userAgent = window.navigator.userAgent;

  const get_historyList = async () => {
    const data = await (
      await fetch(
        `https://playcon.net/api/user/main/history/list/${sel_lang}/all`,
        {
          method: "GET",
        }
      )
    ).json();

    setHistoryList(data);
  };

  const get_inquirytypeList = async () => {
    const data = await (
      await fetch(
        `https://playcon.net/api/user/main/inquiry/list/${sel_lang}/all`,
        {
          method: "GET",
        }
      )
    ).json();

    setInquiryTypelist(data);
  };
  const movePage = (link: string) => {
    const $a = document.createElement("a");
    $a.href = link;
    $a.target = "_blank";
    $a.click();
  };
  const exec_application = async (fdData: FormData) => {
    try {
      fdData.append("lang_cd", "KR");
      const data = await (
        await fetch("https://playcon.net/api/user/main/inquiry/application", {
          method: "POST",
          body: fdData,
        })
      ).json();

      if (data.message === "Insert Success") {
        alert("등록 완료");
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [listShow, setListShow] = useState(0);

  const [slidesPerView, setSlidesPerView] = useState(() => {
    if (window.innerWidth <= lg) {
      if (window.innerWidth <= md) {
        if (window.innerWidth <= sm) {
          return 1;
        }
        return 2;
      }
      return 3;
    }
    return 4;
  });
  const {
    control,
    register,
    watch,
    formState: { errors },
    reset,
  } = useForm<FieldValues>();
  const checkboxValue = watch("inq_agree");

  useEffect(() => {
    const windowResizeEvent = (e: any) => {
      console.log(e.currentTarget.innerWidth);
      if (e.currentTarget.innerWidth <= lg) {
        if (e.currentTarget.innerWidth <= md) {
          setSlidesPerView(2);
          if (e.currentTarget.innerWidth <= sm) {
            setSlidesPerView(1);
          }
        } else {
          setSlidesPerView(3);
        }
      } else {
        setSlidesPerView(4);
      }
    };
    window.addEventListener("resize", windowResizeEvent);
    return () => {
      window.removeEventListener("resize", windowResizeEvent);
    };
  }, [slidesPerView]);

  useEffect(() => {
    get_historyList();
    get_inquirytypeList();
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const onSubmitForm: FormSubmitHandler<FieldValues> = ({ formData, data }) => {
    exec_application(formData);
  };

  return (
    <>
      <div className="flex flex-col">
        <Header />
        <div
          className="overflow-x-hidden"
          style={{
            backgroundImage: "url(/images/00_home.png)",
          }}
        >
          <div className="container flex mx-auto items-center flex-col md:flex-row justify-center">
            <img
              src="/images/그룹 3758@3x.png"
              alt="그룹 3758@3x.png"
              className="md:max-w-[50%] translate-x-[15px] translate-y-[30px] md:translate-y-0 overflow-x-hidden"
            />
            <img
              src="/images/00_home2.png"
              alt="00_home2.png"
              className="md:max-w-[50%]"
            />
          </div>
        </div>
        <div
          className="bg-cover bg-center"
          style={{
            backgroundImage: "url(/images/00_home02.png)",
          }}
        >
          <div className="container mx-auto flex justify-center sm:justify-end py-[109px] px-[16.5px] md:px-0 text-balance">
            <div>
              <img
                src="/images/NoPath - 복사본 (4).png"
                className="mx-auto sm:!ml-auto sm:!mr-0 mb-[15px]"
              />
              <p className="text-white   sm:text-[1.35rem] leading-[1.6] text-center sm:text-end">
                플레이콘은 만화를 활용한 기술을 개발하여
                <br /> 다양한 플랫폼에 유통을 하고 있습니다.
              </p>
            </div>
          </div>
        </div>
        <h3 className="self-center mt-[50px] md:mt-[80px] mb-[30px] text-[1.35rem] font-bold">
          비즈니스영역
        </h3>
        <div className="container flex flex-col sm:flex-row gap-[15px] mx-auto px-[16.5px] sm:px-0">
          <div className="flex flex-1 shadow-[0_3px_6px_0_rgba(0,0,0,0.16)] sm:justify-center items-center gap-[40px] py-[62px] px-[16.5px] sm:px-0 hover:cursor-pointer">
            <img
              src="/images/free-icon-comic-5705544.png"
              alt="free-icon-comic-5705544"
              width={44}
            />
            <div className="flex flex-col gap-[10px]">
              <h4 className="font-bold">흑백만화 웹툰화</h4>
              <p className="">흑백만화를 웹툰으로 만드는 웹툰화 기술</p>
            </div>
          </div>
          <div
            className="flex flex-1 shadow-[0_3px_6px_0_rgba(0,0,0,0.16)] sm:justify-center items-center gap-[40px] py-[62px] px-[16.5px] sm:px-0 hover:cursor-pointer"
            onClick={() => movePage("https://ideapc.kr/")}
          >
            <img src="/images/logo.png" alt="logo" width={44} />
            <div className="flex flex-col gap-[10px]">
              <h4 className="font-bold">IDEA PC</h4>
              <p className="">이미지해상도개선 프로그램</p>
            </div>
          </div>
        </div>
        <h3 className="self-center mt-[50px] md:mt-[80px] mb-[30px] text-[1.35rem] font-bold">
          HISTORY
        </h3>
        <div className="container mx-auto px-[16.5px] sm:px-0">
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={15}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Navigation, Autoplay]}
            className="mySwiper"
          >
            {typeof historylist !== "undefined" ? (
              <>
                {Object.keys(historylist.data).map((_, index) => (
                  <SwiperSlide
                    key={index}
                    className="bg-[#ddd] hover:cursor-pointer relative aspect-square"
                    onMouseOver={(e) => {
                      setListShow(index);
                    }}
                    onMouseOut={(e) => {
                      setListShow(-1);
                    }}
                    onClick={() => {
                      movePage(historylist.data[index].link);
                    }}
                  >
                    <img
                      src={`https://playcon.net/api/common/file/image/${historylist.data[index].file_id}`}
                      className="w-full h-full"
                    />
                    {listShow === index ? (
                      <>
                        <div className="bg-[#222222] bg-opacity-70 z-[1] absolute bottom-0 inset-x-0 text-white p-[11px]">
                          <p className="font-bold">
                            {historylist.data[index].history_ymd
                              .slice(0, 7)
                              .replace("-", ".")}
                          </p>
                          {historylist.data[index].content}
                        </div>
                      </>
                    ) : null}
                  </SwiperSlide>
                ))}
              </>
            ) : null}
          </Swiper>
        </div>
        <h3 className="self-center mt-[50px] md:mt-[80px] mb-[30px] text-[1.35rem] font-bold">
          CONTACT
        </h3>
        <div className="container mx-auto flex flex-col md:flex-row px-[16.5px] sm:px-0 mb-[80px] md:mb-[100px] gap-[15px]">
          <Form
            control={control}
            className="flex-1 border border-[#d2d2d7] flex flex-col py-[30px] px-[16.5px] gap-[15px]"
            onSubmit={onSubmitForm}
          >
            <label className="flex flex-col md:flex-row gap-y-[10px] md:items-center flex-1">
              <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030] basis-[24%]">
                문의유형
              </h3>
              <select
                className="bg-[#f9f9f9] p-[10px] flex-1"
                {...register("inquiry_type_id", {
                  required: true,
                })}
                defaultValue={""}
              >
                <option value={""} disabled>
                  --문의유형을 선택하세요.--
                </option>

                {typeof inquiryTypelist !== "undefined" ? (
                  <>
                    {Object.keys(inquiryTypelist.data).map((_, index) => (
                      <option
                        key={index}
                        value={inquiryTypelist.data[index].inquiry_type_id}
                      >
                        {inquiryTypelist.data[index].inquiry_type_name}
                      </option>
                    ))}
                  </>
                ) : null}
              </select>
            </label>
            <label className="flex flex-col md:flex-row gap-y-[10px] md:items-center flex-1">
              <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030] basis-[24%]">
                이름
              </h3>
              <input
                type="text"
                className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                placeholder="담당자 성함을 입력하세요."
                {...register("name", {
                  required: true,
                })}
              />
            </label>
            <label className="flex flex-col md:flex-row gap-y-[10px] md:items-center flex-1">
              <h3 className="font-bold  basis-[24%]">회사</h3>
              <input
                type="text"
                className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                placeholder="회사명을 입력하세요."
                {...register("company_name")}
              />
            </label>
            <label className="flex flex-col md:flex-row gap-y-[10px] md:items-center flex-1">
              <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030] basis-[24%]">
                연락처
              </h3>
              <input
                type="text"
                className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                placeholder="연락처를 '-' 없이 입력하세요."
                {...register("tel", {
                  required: true,
                  pattern: /^[0-9]+$/,
                })}
              />
            </label>
            <label className="flex flex-col md:flex-row gap-y-[10px] md:items-center flex-1">
              <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030] basis-[24%]">
                E-mail
              </h3>
              <input
                type="email"
                className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                placeholder="E-mail 을 입력하세요."
                {...register("email", {
                  required: true,
                })}
              />
            </label>
            <label className="flex flex-col gap-y-[10px]">
              <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030]">
                문의내용
              </h3>
              <textarea
                className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                placeholder="문의하실 내용을 입력하세요."
                rows={10}
                {...register("content", {
                  required: true,
                })}
              />
            </label>
            <label className="flex flex-col self-center">
              <div className="flex gap-x-[7px] self-center hover:cursor-pointer">
                <input
                  type="checkbox"
                  className="hidden"
                  {...register("inq_agree", {
                    required: true,
                  })}
                />
                <GoCheckCircle
                  size={21}
                  color={checkboxValue === true ? "#2760e5" : "#d2d2d7"}
                />
                <p className="text-[#969696]">개인정보처리방침에 동의합니다.</p>
              </div>
              {errors["inq_agree"]?.type === "required" ? (
                <p className="text-[#ff3030] self-center">
                  개인정보처리방침 동의가 필요합니다.
                </p>
              ) : null}
            </label>
            <p className="font-bold self-center hover:cursor-pointer underline underline-offset-4">
              전문보기
            </p>
            <button className="border border-[#2760e5] mt-[10px] py-[12px] font-bold text-[#2760e5] rounded-[22.5px]">
              문의 신청하기
            </button>
          </Form>
          <div className="flex-1 flex flex-col">
            <h2 className="text-[1.35rem] font-bold mb-[20px]">
              플레이콘 오시는 길
            </h2>
            <h3 className="mb-[10px] font-bold  ">주소</h3>
            <h4 className="mb-[20px]">
              (63178) 제주특별자치도 제주시 서사로 83 3층
            </h4>
            <h3 className="mb-[10px] font-bold">연락처</h3>
            <table>
              <tbody>
                <tr>
                  <td>Fax</td>
                  <td>031-5182-9048</td>
                </tr>
                <tr>
                  <td>Tel</td>
                  <td>070-8825-5004</td>
                </tr>
                <tr>
                  <td>E-mail</td>
                  <td>playcon@ideaconcert.com</td>
                </tr>
              </tbody>
            </table>

            <Map
              center={{ lat: 33.50482759049004, lng: 126.52012968812959 }}
              className="min-h-[300px] flex-1 mt-[30px] border border-[#d2d2d7]"
              level={1}
              zoomable={true}
              draggable={true}
            >
              <MapMarker
                position={{ lat: 33.50482759049004, lng: 126.52012968812959 }}
              ></MapMarker>
            </Map>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Main;
