import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams, useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";

interface Detl {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const Ip = () => {
  const sel_lang = "KR";
  const { id } = useParams();
  const [detail, setDetail] = useState<Detl>();
  const [links, setLinks] = useState<Detl>();

  const getDetail = async () => {
    try {
      const data = await (
        await fetch(
          `https://playcon.net/api/user/ip/detail/${sel_lang}/${id}`,
          {
            method: "GET",
          }
        )
      ).json();

      setDetail(data);
    } catch (error) {
      console.error(error);
    }
  };
  const getLinks = async () => {
    try {
      const data = await (
        await fetch(
          `https://playcon.net/api/user/ip/detail/links/${sel_lang}/${id}`,
          {
            method: "GET",
          }
        )
      ).json();

      setLinks(data);
    } catch (error) {
      console.error(error);
    }
  };
  const movePage = (link: string) => {
    const $a = document.createElement("a");
    $a.href = link;
    $a.target = "_blank";
    $a.click();
  };

  useEffect(() => {
    getDetail();
    getLinks();
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col">
        <Header />
        <div
          className="flex flex-col justify-center items-center gap-[10px] py-[90px] text-white bg-right bg-cover relative"
          style={{
            backgroundImage: "url(/images/IP_LIST_back@3x.png)",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          <h2 className="text-[1.35rem] font-bold z-[1]">IP LIST</h2>
          <h2 className="  z-[1]">언제나 즐거운 플레이, 플레이콘</h2>
        </div>
        <div className="container mx-auto flex flex-col px-[16.5px] md:px-0">
          <div className="flex gap-[5px] items-center mt-[50px] mb-[30px]">
            <FiChevronLeft
              size={22}
              color="#2760e5"
              className="hover:cursor-pointer"
              onClick={() => navigate("/ip")}
            />
            <p className="text-[#969696]">IP 리스트 돌아가기</p>
          </div>
          <div className="flex flex-col md:flex-row gap-x-[15px] gap-y-[20px] mb-[50px]">
            <img
              src={`https://playcon.net/api/common/file/image/${detail?.data[0].file_id}`}
              className="md:self-start md:max-w-[40%]"
            />
            <div className="flex-1 flex flex-col">
              <div className="flex justify-between items-center">
                <h2 className="font-bold text-[1.35rem]">
                  {detail?.data[0].title}
                </h2>
                <p className="text-[#969696]">
                  {detail?.data[0].launch_ymd.slice(0, 10)}{" "}
                  {detail?.data[0].launch_status === "L" ? (
                    <>런칭</>
                  ) : (
                    <>예정</>
                  )}{" "}
                </p>
              </div>
              <hr className="border border-[#39adef] my-[19px]" />
              <p className="leading-[1.87]">
                {detail?.data[0].synopsys.split("\n").map((line: any) => {
                  return (
                    <span>
                      {line}
                      <br />
                    </span>
                  );
                })}
                {}
              </p>
              <button
                type="button"
                onClick={() => {
                  movePage(detail?.data[0].origin_link);
                }}
                className="border border-[#2760e5] text-[#2760e5] py-[12px] md:w-[50%] my-[30px] hover:cursor-pointer tracking-[-0.26px] font-bold"
              >
                원작 보러가기
              </button>
              <hr className="border border-[#d2d2d7]" />
              <ul className="my-[30px]   flex flex-col gap-y-[20px]">
                <li className="flex items-start">
                  <div className="w-[150px] flex gap-x-[10px] items-center shrink-0">
                    <img src="/images/icon-user@3x.webp" width={24} />
                    <p className="font-bold">작가</p>
                  </div>
                  <ul className="text-[#969696] flex gap-x-[9px] flex-wrap">
                    <li>{detail?.data[0].author_name}</li>
                  </ul>
                </li>
                <li className="flex items-start">
                  <div className="w-[150px] flex gap-x-[10px] items-center shrink-0">
                    <img src="/images/icon-light@3x.webp" width={24} />
                    <p className="font-bold">키워드</p>
                  </div>

                  <ul className="text-[#969696] flex gap-x-[9px] flex-wrap">
                    {detail?.data[0].keyword
                      .split(",")
                      .map((_: any, index: any) => (
                        <li key={index}>
                          {detail?.data[0].keyword.split(",")[index]}
                        </li>
                      ))}
                  </ul>
                </li>
                <li className="flex items-start">
                  <div className="w-[150px] flex gap-x-[10px] items-center shrink-0">
                    <img src="/images/icon-computer@3x.webp" width={24} />
                    <p className="font-bold">플랫폼</p>
                  </div>

                  <ul className="text-[#969696] underline underline-offset-[3px] flex flex-col gap-y-[9px] items-start">
                    {links?.data.map((_: any, index: any) => (
                      <li
                        key={index}
                        className="hover:cursor-pointer"
                        onClick={() => {
                          movePage(links?.data[index].platform_link);
                        }}
                      >
                        {links?.data[index].platform_name}
                      </li>
                    ))}

                    {/* <li className="hover:cursor-pointer">
                      카카오페이지 보러가기
                    </li>
                    <li className="hover:cursor-pointer">
                      카카오 웹툰 보러가기
                    </li>
                    <li className="hover:cursor-pointer">네이버 보러가기</li> */}
                  </ul>
                </li>
              </ul>
              <hr className="border border-[#d2d2d7]" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Ip;
