import React, { useEffect, useState } from "react";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { Map, MapMarker } from "react-kakao-maps-sdk";
import { useForm, FieldValues, FormSubmitHandler, Form } from "react-hook-form";
import { GoCheckCircle } from "react-icons/go";

const Contact = () => {
  interface List {
    code: string;
    status: number;
    success: boolean;
    message: string;
    data: any;
  }
  const sel_lang = "KR";
  const [inquiryTypelist, setInquiryTypelist] = useState<List>();
  const get_inquirytypeList = async () => {
    const data = await (
      await fetch(
        `https://playcon.net/api/user/main/inquiry/list/${sel_lang}/all`,
        {
          method: "GET",
        }
      )
    ).json();

    setInquiryTypelist(data);
  };

  const exec_application = async (fdData: FormData) => {
    try {
      fdData.append("lang_cd", "KR");
      const data = await (
        await fetch("https://playcon.net/api/user/main/inquiry/application", {
          method: "POST",
          body: fdData,
        })
      ).json();

      if (data.message === "Insert Success") {
        alert("등록 완료");
        reset();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const movePage = (link: string) => {
    const $a = document.createElement("a");
    $a.href = link;
    $a.target = "_blank";
    $a.click();
  };
  const {
    register,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<FieldValues>();
  const checkboxValue = watch("agree");
  const onSubmitForm: FormSubmitHandler<FieldValues> = ({ formData, data }) => {
    console.log(data);
    exec_application(formData);
  };
  useEffect(() => {
    get_inquirytypeList();
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <>
      <div className="flex flex-col">
        <Header />
        <div
          className="flex flex-col justify-center items-center gap-[10px] py-[90px] text-white bg-right bg-cover relative text-balance"
          style={{
            backgroundImage: "url(/images/CONTACT_back@3x.webp)",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          <h2 className="text-[1.35rem] font-bold z-[1]">CONTACT</h2>
          <h2 className="  z-[1] text-center">
            플레이콘이 좋은 웹툰을 만들어나갈 수 있도록 함께해 주세요.
          </h2>
        </div>
        <div className="container mx-auto px-[16.5px] md:px-0 ">
          <h2 className="text-[1.35rem] self-center font-bold mb-[10px] mt-[50px] flex justify-center">
            플레이콘은 지금 웹툰작가 모집중!
          </h2>
          <div className=" self-center flex justify-center">
            <hr className="border border-[#2760e5] w-[30px] mb-[25px] self-center" />
          </div>

          <p className="self-center flex justify-center">
            (63178) 제주특별자치도 제주시 서사로 83 3층
          </p>
          <div className="flex gap-[10px] self-center my-[15px] flex justify-center">
            <p>Tel</p>
            <p>070-8825-5004</p>
          </div>
          <div className="flex gap-[10px] self-center flex justify-center">
            <p>E-mail</p>
            <p>playcon@ideaconcert.com</p>
          </div>
        </div>

        <div className="container mx-auto px-[16.5px] md:px-0 flex mt-[30px] flex-col md:flex-row aspect-[1/1] md:aspect-auto gap-[15px]">
          <Map
            center={{ lat: 33.50482759049004, lng: 126.52012968812959 }}
            className="flex-1 border border-[#d2d2d7] "
            level={1}
            zoomable={true}
            draggable={true}
          >
            <MapMarker
              position={{ lat: 33.50482759049004, lng: 126.52012968812959 }}
            ></MapMarker>
          </Map>
          <div className="flex-0 md:flex-1 flex flex-col gap-[19px]">
            <img
              src="/images/CONTACT_img@3x.webp"
              alt="/images/CONTACT_img@3x.webp"
              className="hidden md:flex"
            />
            <button
              type="button"
              onClick={() => {
                movePage("https://ideaconcert.art");
              }}
              className="tracking-[-0.29px]   mt-[15px] md:mt-0 border border-[#2760e5] text-[#2760e5] py-[12px] font-bold rounded-[22.5px] md:mx-auto md:px-[140px] text-nowrap"
            >
              지원하러 가기
            </button>
          </div>
        </div>
        <div className="container mx-auto my-[49.5px] px-[16.5px] md:px-0">
          <hr className="border border-[#d2d2d7]" />
        </div>
        <h2 className="text-[1.35rem] self-center font-bold mb-[10px] mt-[50px]">
          제휴문의
        </h2>
        <hr className="border border-[#2760e5] w-[30px] mb-[19px] self-center" />
        <Form
          control={control}
          className="container mx-auto flex-1 flex flex-col px-[16.5px] md:px-0"
          onSubmit={onSubmitForm}
        >
          <div className="flex flex-col md:flex-row gap-[11px] md:gap-[20px]">
            <div className="flex-1 flex flex-col gap-[11px] justify-between">
              <label className="flex flex-col gap-[10px]">
                <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030] basis-[22%]">
                  문의유형
                </h3>
                <select
                  className="bg-[#f9f9f9] p-[10px] flex-1"
                  {...register("inquiry_type_id", {
                    required: true,
                  })}
                  defaultValue={""}
                >
                  <option value={""} disabled>
                    --문의유형을 선택하세요.--
                  </option>

                  {typeof inquiryTypelist !== "undefined" ? (
                    <>
                      {Object.keys(inquiryTypelist.data).map((_, index) => (
                        <option
                          key={index}
                          value={inquiryTypelist.data[index].inquiry_type_id}
                        >
                          {inquiryTypelist.data[index].inquiry_type_name}
                        </option>
                      ))}
                    </>
                  ) : null}
                </select>
              </label>
              <label className="flex flex-col gap-[10px]">
                <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030] basis-[22%]">
                  이름
                </h3>
                <input
                  type="text"
                  className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                  placeholder="담당자 성함을 입력하세요."
                  {...register("name", {
                    required: true,
                  })}
                />
              </label>
              <label className="flex flex-col gap-[10px]">
                <h3 className="font-bold  basis-[22%]">회사</h3>
                <input
                  type="text"
                  className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                  placeholder="회사명을 입력하세요."
                  {...register("company_name")}
                />
              </label>
              <label className="flex flex-col gap-[10px]">
                <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030] basis-[22%]">
                  연락처
                </h3>
                <input
                  type="text"
                  className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                  placeholder="연락처를 '-' 없이 입력하세요."
                  {...register("tel", {
                    required: true,
                    pattern: /^[0-9]+$/,
                  })}
                />
              </label>
              <label className="flex flex-col gap-[10px]">
                <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030] basis-[22%]">
                  E-mail
                </h3>
                <input
                  type="email"
                  className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                  placeholder="E-mail 을 입력하세요."
                  {...register("email", {
                    required: true,
                  })}
                />
              </label>
            </div>
            <label className="flex-1 flex flex-col gap-[10px]">
              <h3 className="font-bold after:content-['*'] after:ml-[2px] after:text-[#ff3030]">
                문의내용
              </h3>

              <textarea
                className="bg-[#f9f9f9] p-[10px] flex-1 placeholder-[#969696]"
                placeholder="문의하실 내용을 입력하세요."
                rows={10}
                {...register("content", {
                  required: true,
                })}
              />
            </label>
          </div>
          <div className="flex flex-col md:flex-row justify-center gap-[9px] md:gap-[14px] mt-[31px]">
            <label className="flex gap-[7px] self-center hover:cursor-pointer">
              <input
                type="checkbox"
                className="hidden"
                {...register("agree", {
                  required: true,
                })}
              />
              <GoCheckCircle
                size={21}
                color={checkboxValue === true ? "#2760e5" : "#d2d2d7"}
              />
              <p className="text-[#969696]">개인정보처리방침에 동의합니다.</p>
            </label>
            <p className="font-bold self-center hover:cursor-pointer underline underline-offset-4">
              전문보기
            </p>
          </div>
          {errors["agree"]?.type === "required" ? (
            <p className="text-[#ff3030] self-center text-center text-balance mt-1">
              PLAYCON 서비스 이용을 위해서 반드시 동의가 필요합니다.
            </p>
          ) : null}
          <button className="border border-[#2760e5] py-[12px] font-bold text-[#2760e5] rounded-[22.5px] mt-[31px] mb-[50px] md:px-[140px] md:mx-auto">
            문의 신청하기
          </button>
        </Form>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
