import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

interface InformDetl {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const InformDetail = () => {
  const sel_lang = "KR";

  const [detail, setDetail] = useState<InformDetl>();

  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id);

  const getDetail = async (p: any) => {
    try {
      const data = await (
        await fetch(
          `https://playcon.net/api/user/notice/detail/${sel_lang}/${p}`,
          {
            method: "GET",
          }
        )
      ).json();
      console.log(data);
      setDetail(data);
    } catch (error) {
      console.error(error);
    }
  };

  const download = (id: any) => {
    let fileName = `https://playcon.net/api/common/file/download/${id}`;
    const element = document.createElement("a");
    element.href = fileName;
    element.download = fileName;
    document.body.appendChild(element); // FireFox
    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    getDetail(id);
  }, [id]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <Header />
        <div className="bg-[#E2F3FD] flex flex-col  gap-y-[10px] justify-center items-center py-[50px] relative">
          <img
            src="/images/04_INFORM@3x.webp"
            className="absolute left-0 h-full hidden lg:block"
          />
          <img
            src="/images/05_INFORM@3x.webp"
            className="absolute right-0 h-full hidden lg:block"
          />
          <h2 className="text-[1.35rem] font-bold z-[1]">INFORM</h2>
          <h2 className="  leading-[1.65] text-center z-[1]">
            플레이콘의 소식을 알려드려요.
          </h2>
        </div>
        <div className="container mx-auto px-[16.5px] md:px-0 flex flex-col">
          <div className="border-t border-b py-[14.5px] border-[#ddd] mt-[49px] mb-[10px] flex flex-col gap-y-[10px] md:flex-row md:justify-between px-[15px] md:px-[30px]">
            <h2 className="font-bold">
              {detail?.data.detail[0]?.title ?? "타이틀"}
            </h2>
            <p className="text-[#969696]">
              {detail?.data.detail[0]?.notice_ymd.slice(0, 10) ?? "날짜"}
            </p>
          </div>
          <div className="p-[15px] md:p-[30px] bg-[#f9f9f9]">
            {detail?.data.detail[0]?.content ?? "콘텐츠"}
            <div className="mt-[40px]">
              {detail?.data.files.map((_: any, index: any) => (
                <p
                  key={index}
                  className="text-[#0066ff]"
                  onClick={() => download(detail.data.files[index].file_id)}
                >
                  {detail.data.files[index]?.file_ori_name}
                </p>
              ))}
            </div>
          </div>

          <button
            className="border border-[#2760e5] text-[#2760e5] font-bold tracking-[-0.26px] py-[12.5px] mt-[30px] mb-[50px] md:px-[140px] md:self-center"
            onClick={() => navigate("/inform")}
          >
            목록으로 가기
          </button>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default InformDetail;
