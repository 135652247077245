import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";
import Main from "./pages/Main";
import About from "./pages/About";
import IpList from "./pages/IpList";
import Contact from "./pages/Contact";
import IpDetail from "./pages/IpDetail";
import Inform from "./pages/Inform";
import InformDetail from "./pages/InformDetail";

const router = createBrowserRouter([
  {
    path: "*",
    loader: () => {
      return redirect("/");
    },
  },
  {
    path: "/",
    element: <Main />,
    loader: () => {
      return null;
    },
  },
  {
    path: "/about",
    element: <About />,
    loader: () => {
      return null;
    },
  },
  {
    path: "/ip",
    element: <IpList />,
    loader: () => {
      return null;
    },
  },
  {
    path: "/contact",
    element: <Contact />,
    loader: () => {
      return null;
    },
  },
  {
    path: "/ip/:id",
    element: <IpDetail />,
    loader: () => {
      return null;
    },
  },
  {
    path: "/inform",
    element: <Inform />,
    loader: ({ request }) => {
      const url = new URL(request.url);
      if (
        url.searchParams.get("page") === null ||
        url.searchParams.get("page") === "" ||
        isNaN(Number(url.searchParams.get("page")))
      ) {
        return redirect("/inform?page=1");
      }
      return null;
    },
  },
  {
    path: "/inform/:id",
    element: <InformDetail />,
    loader: () => {
      return null;
    },
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
