import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <>
      <div className="flex flex-col">
        <Header />
        <div className="bg-[#E2F3FD] flex flex-col justify-center items-center py-[50px] relative px-[16.5px] md:px-0 text-balance">
          <img
            src="/images/02_ABOUT US@3x.webp"
            className="absolute left-0 h-full hidden sm:block"
          />
          <img
            src="/images/01_ABOUT US@3x.webp"
            className="absolute right-0 h-full hidden sm:block"
          />
          <img src="/images/노란_기본.png" className="z-[1]" />
          <h2 className="text-[1.35rem] font-bold mt-[10px] mb-[14px] z-[1]">
            ABOUT US
          </h2>
          <h2 className="  leading-[1.65] text-center z-[1]">
            제작부터 기술연구, 글로벌 유통까지.
            <br />
            종합콘텐츠기업 플레이콘.
          </h2>
        </div>
        <h2 className="container self-center text-center font-bold text-[1.35rem] mt-[50px] mb-[20px] text-balance">
          콘텐츠x기술에 즐거움을 더하는{" "}
          <span className="text-[#2760e5]">플레이콘</span>
        </h2>
        <p className="container self-center text-center leading-[1.65] px-[30px] text-balance">
          플레이콘은 보다 많은 사람들이 향유할 수 있는 웹툰을 만듭니다.
          <br /> 웹툰을 제작하며 기술을 연구하고, 해외 런칭까지 겸하여 국·내외
          시장을 선도하고 있는 종합콘텐츠기업입니다.
        </p>
        <div className="container flex mx-auto px-[9px] flex-wrap">
          <img src="/images/01_ABOUT US (2)@3x.png" className="my-[50px]" />
        </div>

        <img
          src="/images/free-icon-comic-5705544.png"
          width={30}
          className="self-center"
        />
        <h2 className="text-[1.35rem] font-bold my-[10px] self-center">
          흑백만화 웹툰화
        </h2>
        <hr className="w-[30px] self-center border border-[#2760e5]" />
        <div className="container flex mx-auto px-[9px] flex-wrap">
          <div className="md:basis-1/2 px-[7.5px] basis-full flex flex-col">
            <h2 className="text-[1.35rem] font-bold mt-[29px]">
              이미지 복원 기술
            </h2>
            <hr className="my-[19px] border border-[#39adef]" />
            <p className="leading-[1.65] mb-[20px]">
              물리적으로 파괴되어 있는 이미지 스캔본을 딥러닝 AI로 복원하는 기술
            </p>
            <img src="/images/tech07_main_img@3x.png" />
          </div>
          <div className="md:basis-1/2 px-[7.5px] basis-full flex flex-col">
            <h2 className="text-[1.35rem] font-bold mt-[29px]">
              이미지 노이즈 제거 기술
            </h2>
            <hr className="my-[19px] border border-[#39adef]" />
            <p className="leading-[1.65] mb-[20px]">
              해상도가 낮은 파일의 노이즈를 제거하여 보다 선명하게 보이도록 하는
              기술
            </p>
            <img src="/images/Tech08_main_img@3x.png" />
          </div>
          <div className="md:basis-1/2 px-[7.5px] basis-full flex flex-col">
            <h2 className="text-[1.35rem] font-bold mt-[29px]">
              이미지 해상도 업그레이드 기술
            </h2>
            <hr className="my-[19px] border border-[#39adef]" />
            <p className="leading-[1.65] mb-[20px]">
              해상도가 낮아 흐릿한 이미지를 높은 해상도로 재가공하는 기술
            </p>
            <img src="/images/tech01_middle2@3x.png" />
          </div>
          <div className="md:basis-1/2 px-[7.5px] basis-full flex flex-col">
            <h2 className="text-[1.35rem] font-bold mt-[29px]">
              만화 컷 분리 기술
            </h2>
            <hr className="my-[19px] border border-[#39adef]" />
            <p className="leading-[1.65] mb-[20px]">
              AI 로 컷을 자동분리 해주고, 분리된 컷을 순차적으로 배치해주는 자동
              웹툰화 기술
            </p>
            <img src="/images/그룹 3800@3x.png" />
          </div>
        </div>
        <div className="bg-[#fafafa] flex flex-col justify-center items-center py-[50px] md:py-[100px] mt-[50px]">
          <img src="/images/logo.png" />
          <h2 className="text-[1.35rem] font-bold my-[10px]">
            이미지해상도개선프로그램
          </h2>
          <hr className="border border-[#2760e5] w-[30px] mb-[19px]" />
          <img
            src="/images/computer@3x.png"
            className="self-stretch container mx-auto"
          />
          <div className="flex flex-col md:flex-row justify-around self-stretch container mx-auto gap-[25px] md:gap-0 mt-[50px]">
            <div className="flex flex-col gap-[15px] items-center">
              <img src="/images/ico_highquality@3x.png" width={50} />
              <p className="text-[1.35rem] font-bold">높은 품질</p>
            </div>
            <div className="flex flex-col gap-[15px] items-center">
              <img src="/images/ico_free@3x.png" width={50} />
              <p className="text-[1.35rem] font-bold">100% 무료 온라인 변환</p>
            </div>
            <div className="flex flex-col gap-[15px] items-center">
              <img src="/images/ico_conversion@3x.png" width={50} />
              <p className="text-[1.35rem] font-bold">간편한 사용법</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default About;
