import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";

interface IpList {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const IpList = () => {
  const sel_lang = "KR";
  const [ipList, setIpList] = useState<IpList>();

  const setIpListAll = async () => {
    try {
      const data = await (
        await fetch(`https://playcon.net/api/user/ip/list/${sel_lang}/all`, {
          method: "GET",
        })
      ).json();

      setIpList(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIpListAll();
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const navigate = useNavigate();
  return (
    <>
      <div className="flex flex-col">
        <Header />
        <div
          className="flex flex-col justify-center items-center gap-[10px] py-[90px]  text-white bg-right bg-cover relative"
          style={{
            backgroundImage: "url(/images/IP_LIST_back@3x.png)",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-30"></div>
          <h2 className="text-[1.35rem] font-bold z-[1]">IP LIST</h2>
          <h2 className="  z-[1]">언제나 즐거운 플레이, 플레이콘</h2>
        </div>
        <h2 className="text-[1.35rem] self-center font-bold mb-[10px] mt-[50px]">
          플레이콘 작품 리스트
        </h2>

        <hr className="border border-[#2760e5] w-[30px] mb-[19px] self-center" />
        <div className="flex flex-wrap container mx-auto pb-[100px]">
          {typeof ipList !== "undefined" ? (
            <>
              {Object.keys(ipList.data).map((_, index) => (
                <div
                  key={index}
                  className="basis-1/2 sm:basis-1/3 lg:basis-1/4 px-[9px] mb-[25px] hover:cursor-pointer"
                  onClick={() =>
                    navigate("/ip/" + ipList.data[index].intprp_id)
                  }
                >
                  <img
                    src={`https://playcon.net/api/common/file/image/${ipList.data[index].file_id}`}
                    className="aspect-[300/360]"
                  />
                  <h2 className="font-bold mt-[10px]">
                    {ipList.data[index].title}
                  </h2>
                  <p className="text-[12px] text-[#969696]">
                    {ipList.data[index].author_name}
                  </p>
                </div>
              ))}
            </>
          ) : null}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default IpList;
