import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";

interface InformList {
  code: string;
  status: number;
  success: boolean;
  message: string;
  data: any;
}

const Inform = () => {
  const sel_lang = "KR";

  const [noticelist, setNoticeList] = useState<InformList>();
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getNoticeList = async (p: any) => {
    try {
      const data = await (
        await fetch(
          `https://playcon.net/api/user/notice/list/all/${sel_lang}/${p}`,
          {
            method: "GET",
          }
        )
      ).json();

      setNoticeList(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setSearchParams({
      page: Number(page).toString(),
    });

    if (page === 1) {
      getNoticeList(0);
    } else {
      const cal_page = (page - 1) * 10;
      getNoticeList(cal_page);
    }
  }, [page]);

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  const move = (pagenum: any) => {
    setPage(pagenum);
  };

  const toPageArr = (input_page: number) => {
    const page = [];
    for (let i = 1; i <= input_page; i++) {
      page.push(i);
    }
    return page;
  };

  return (
    <>
      <div className="flex flex-col">
        <Header />
        <div className="bg-[#E2F3FD] flex flex-col  gap-y-[10px] justify-center items-center py-[50px] relative">
          <img
            src="/images/04_INFORM@3x.webp"
            className="absolute left-0 h-full hidden lg:block"
          />
          <img
            src="/images/05_INFORM@3x.webp"
            className="absolute right-0 h-full hidden lg:block"
          />
          <h2 className="text-[1.35rem] font-bold z-[1]">INFORM</h2>
          <h2 className="  leading-[1.65] text-center z-[1]">
            플레이콘의 소식을 알려드려요.
          </h2>
        </div>
        <h2 className="text-[1.35rem] self-center font-bold mb-[10px] mt-[50px]">
          공지사항
        </h2>
        <hr className="border border-[#2760e5] w-[30px] self-center" />
        <ul className="container mx-auto my-[30px] border-t border-[#d2d2d7]">
          {typeof noticelist !== "undefined" ? (
            <>
              {Object.keys(noticelist.data).map((_, index) => (
                <li
                  key={index}
                  className="hover:bg-[#f9f9f9] border-b border-[#d2d2d7] hover:cursor-pointer flex py-[20px] px-[10px] md:px-[30px]"
                  onClick={() =>
                    navigate(`/inform/${noticelist.data[index].notice_id}`)
                  }
                >
                  <p>{noticelist.data[index].notice_id}</p>
                  <p className="flex-1 text-ellipsis overflow-hidden text-nowrap px-[10px]">
                    {noticelist.data[index].title}
                  </p>
                  <p className="ml-auto text-[#969696]">
                    {noticelist.data[index].notice_ymd.slice(0, 10)}
                  </p>
                </li>
              ))}
            </>
          ) : null}
        </ul>
        <div className="container mx-auto flex justify-center items-center sm:gap-x-[20px] mb-[50px]">
          <FiChevronLeft
            size={22}
            color="#b5b5b5"
            className="hover:cursor-pointer hover:!text-[#2760e5] hidden"
            onClick={() => {
              if (searchParams.get("page") === "1") return;
              setSearchParams({
                page: (Number(searchParams.get("page")) - 1).toString(),
              });
            }}
          />
          <ul className="flex text-[#b5b5b5] font-bold sm:gap-x-[3px]">
            {typeof noticelist !== "undefined"
              ? Object.keys(noticelist.data).length
                ? toPageArr(Math.ceil(noticelist.data[0].count_all / 10)).map(
                    (_, index) =>
                      searchParams.get("page") === (index + 1).toString() ? (
                        <li
                          key={index}
                          className="text-white bg-[#2760e5] hover:cursor-pointer px-[7px] py-[0px] rounded-[3px]"
                          onClick={(e) => {
                            move(index + 1);
                          }}
                        >
                          {index + 1}
                        </li>
                      ) : (
                        <li
                          key={index}
                          className="hover:text-white hover:bg-[#2760e5] hover:cursor-pointer px-[7px] py-[0px] rounded-[3px]"
                          onClick={(e) => {
                            move(index + 1);
                          }}
                        >
                          {index + 1}
                        </li>
                      )
                  )
                : null
              : null}
          </ul>
          <FiChevronLeft
            size={22}
            color="#b5b5b5"
            className="hover:cursor-pointer rotate-180 hover:!text-[#2760e5] hidden"
            onClick={() => {
              setSearchParams({
                page: (Number(searchParams.get("page")) + 1).toString(),
              });
            }}
          />
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Inform;
